




































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import {VTextField} from 'vuetify/lib/components';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'uOttawa1321Lab3Procedure',
  components: {StembleLatex, STextarea, VTextField},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        procedure: null,
        language: 'en',
      },
      rules: [(v: string) => v?.length <= 3000 || 'Max 3000 characters'],
      questions: [
        {
          en: 'This section should describe what YOU actually did and observed - not necessarily what is stated in the lab manual. The procedure is best listed in point form.',
          fr: 'Cette section doit décrire ce que VOUS avez réellement fait et observé, pas nécessairement ce qui est indiqué dans le manuel. Le protocole est mieux répertorié sous forme de points.',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
